// Function to find an element by id
function findElementById(tree, id) {
  for (const element of tree) {
    if (element.id === id) {
      return element;
    }
    if (element.branches && element.branches.length > 0) {
      const result = findElementById(element.branches, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

// Function to extract, modify, and replace a branch
function extractModifyReplace(tree, id, newContent) {
  const foundElement = findElementById(tree, id);
  if (foundElement) {
    if (!foundElement.branches) {
      foundElement.branches = [];
    }

    const pdfConvBranch = foundElement.branches.find(branch => branch.isPdfConv === true);

    const newUserBranch = {
      id: Math.random(Math.random() * 1000),
      role: "user",
      content: newContent,
      branches: [
        {
          id: Math.random(Math.random() * 1000),
          role: "assistant",
          content: "...",
          branches: []
        }
      ]
    };

    if (pdfConvBranch) {
      if (!pdfConvBranch.branches) {
        pdfConvBranch.branches = [];
      }
      pdfConvBranch.branches.push(newUserBranch);
    } else {
      foundElement.branches.push(newUserBranch);
    }
  }
  return tree;
}

export const updateBranch = ({ data, id, content }) => extractModifyReplace(data, id, content);
