export const initialProject = {
  description: "",
  id: 0,
  inserted_at: "",
  status: 0,
  uid: "",
  updated_at: "",
  user_id: 0
}

export const parseProxyData = (data) => JSON.parse(JSON.stringify(data));

export const findNewestCard = (conversation) => {
  let newestTimestamp = 0;
  let newestConv = null;
  let newestIndex = -1;
  let newestElement = null;

  const searchConversation = (conv) => {
    conv.forEach((card) => {
      const cardElement = document.getElementById(`card-${card.id}`);
      if (cardElement) {
        const timestamp = parseInt(cardElement.getAttribute('data-timestamp') || '0', 10);
        if (timestamp > newestTimestamp) {
          newestTimestamp = timestamp;
          newestConv = conv;
          newestIndex = card.id;
          newestElement = cardElement;
        }
      }
    });

    if (conv.branches) {
      conv.branches.forEach(searchConversation);
    }
  };

  searchConversation(conversation);

  // Correct the index to reflect the card's position in the conversation
  const correctIndex = newestConv ? (newestConv).findIndex((card) => {
    const cardElement = document.getElementById(`card-${card.id}`);
    return cardElement === newestElement;
  }) : -1;

  return { conv: newestConv, index: correctIndex, element: newestElement };
};

export const getProjectStructure = (data = {
  userUid: '',
  name: '',
  text: '',
  session: '',
  parentChatId: null
}) => {
  return {
    parentChatId: data.parentChatId,
    userUid: data.userUid,
    session: data.session,
    name: data.name,
    conversations: [{
      header: 'Welcome',
      cards: [{
        text: data.text,
        options: [
          { label: 'Brainstorm', action: 'Brainstorm' },
          { label: 'Brief', action: 'Brief' },
        ],
        isUser: false,
      }],
    }],
  };
}

// "How about creating a ** Quince Chiffon Cake ** inspired by \"Murta con Membrillo\" and \"Chiffon Pie\"?\n\n**Idea**: A light and airy chiffon cake flavored with quince, complemented by a Chilean guava (murta) reduction sauce. This dessert would bring together the delicate texture of chiffon pie with the rich, fruity flavors of traditional South American ingredients. For an added touch, the cake could be decorated with edible flowers or painted tortillas reflecting Chicano cultural roots, making it both a feast for the eyes and the palate.\n\nWould you like to proceed with this idea or explore further?"
// Function to parse response content
export const parseResponseContent = (responseText) => {
  const buttonRegex = /!\[button: (.*?)\]\s*\((.*?)\)/g;
  const options = [];
  let text = responseText;
  let match = buttonRegex.exec(responseText);

  while (match !== null) {
    options.push({ label: match[1], action: match[2] });
    text = text.replace(match[0], ''); // Remove the button text from the main text
    match = buttonRegex.exec(responseText);
  }

  return options
};

export const clearContentButtons = (text)=> {
  const regex = /!\[button:.*?\]\s*\(.*?\)/g;
  return text.replace(regex, '');
}

export const parseContentCitations = ({ content, citations = [] }) => {
  if(citations.length === 0) {
    return content
  }

  let processedContent = content;
  citations.forEach(citation => {
    const regex = new RegExp(`\\[${citation.number}\\]`, 'g');
    processedContent = processedContent.replace(regex, ` [${citation.number}](${citation.url})`);
  });

  return processedContent;
}

export const getImageUrl = (text) => {
  const urlRegex = /\[(https?:\/\/[^\]]+)\]/;
  const result = text.match(urlRegex);
  if (result && result[1]) {
    return `![url](${result[1]})`;
  } else {
    return '';
  }
}

export const getCleanText = (text) => {
  const cleanButtonText = clearContentButtons(text)
  const cleanRegex = /\[https?:\/\/[^\]]+\]/;
  return cleanButtonText.replace(cleanRegex, '').trim();
}
