import axios from "axios";
import Storage from "localStorage";
import { API_HOST, API, API_BRANCHY } from "../shared/constants/Constants";

export const getThumbnails = (url) =>
  axios.post("https://api.seenapse.it/action/thumbnails", {
    url,
    token: Storage.getItem("token"),
  });
// return axios.post(`http://localhost:7000/api/v1/search/image`, {image: url})

// Debido a que muchos sitios están bloqueando el scraping de su contenido, muchas de las URLs que los usuarios introducen no devuelven ninguna imagen. Esto causa una muy mala UX.
// Solución propuesta: usar el <og:image> (del protocolo Open Graph https://ogp.me) que muchos sitios hacen disponible para cuando se comparten dichos URLs.
// Propongo el siguiente algoritmo:
// 1. Una vez que el campo del URL pierde foco (ver MVD-57), se trata de obtener la imagen de <og:image> y se agrega a un arreglo.
// 1. Se trata de obtener otras imágenes con el método actual y se agregan al arreglo.
// 1. Si el arreglo no contiene ninguna imagen, se usa un color random para pintar el espacio de la seenapse (como hacemos ahora durante la carga y cuando no hay imagen asociada) y el usuario puede salvar sin que le marque error.
// 1. Si el arreglo contiene una sola imagen, se selecciona ésa, y no se le da opción al usuario de seleccionar.
// 1. Si el arreglo contiene >1 y <8 imágenes, se despliegan como hacemos ahora para que el usuario seleccione la imagen que quiere.

export const sendSeenapse = (seenapse) => {
  const userLang = Storage.getItem("lang") || "en";
  const lang = userLang.split("-")[0] === "es" ? "es" : "en";

  return API.post(`${API_HOST}/api/v1/seenapse`, {
    seenapse: {
      ...seenapse,
      user_id: Storage.getItem("uid"),
      lang,
    },
    token: Storage.getItem("token"),
  });
};

export const updateSeenapse = (seenapse) => {
  const id = seenapse.id;
  delete seenapse.id;

  return API.put(`${API_HOST}/api/v1/seenapse/${id}`, {
    seenapse: {
      ...seenapse,
    },
  });
};

export const GetChatHistory = ({ uid, retry }) =>
  API.get(`${API_HOST}/api/v1/projects/chat/${uid}`, {
    params: {
      retry,
    },
  });

export const SsoService = params => axios.post(`${API_HOST}/api/v1/logout`, { params });

export const addIdea = (params) =>
API.post(`${API_HOST}/api/v1/seenapse/ia`, params);

export const getIdeas = (params) =>
API.post(`${API_HOST}/api/v1/seenapse/get-ideas`, params);

export const getInspiration = (params) =>
API.post(`${API_HOST}/api/v1/seenapse/get-inspiration`, params);

export const getTranslation = (params) =>
API.post(`${API_HOST}/api/v1/seenapse/translate-body`, params);

export const GetListProjects = (uid) =>
API.get(`${API_HOST}/api/v1/projects/user/${uid}`);

export const DeleteProject = ({ uid, userUid }) =>
  API.delete(`${API_HOST}/api/v1/projects/${uid}/${userUid}`);

export const AddProject = (params) =>
API.post(`${API_HOST}/api/v1/projects`, { ...params });

export const AddMessage = (params) =>
API.post(`${API_HOST}/api/v1/projects/chat-v2`, { ...params });

export const ChangeProjectStatus = ({ params, uid }) =>
API.put(`${API_HOST}/api/v1/projects/${uid}`, { ...params });

export const AddClimates = (params) =>
API.post(`${API_HOST}/api/v1/seenapse/climates`, params);

export const ShareBranchy = async ({ projectUid, email }) => {
  const { data } = await API.post(`${API_HOST}/api/v1/projects/${projectUid}/collaborators/email`, { email });
  return data;
}

export const CreateBranchy = async (params) => {
  const { data } = await API.post(`${API_HOST}/api/v1/projects/chat/branchy`, params);
  return data;
}

export const AddBranchyPdf = async ({ projectUid, form}) => {
  const { data } = await API.post(`${API_HOST}/api/v1/projects/${projectUid}/analyze-pdf`, form);
  return data;
}

export const GetBranchy = async (uid) => {
  const { data } = await API.get(`${API_HOST}/api/v1/projects/chat/branchy/${uid}`);
  return data;
}

export const GetBranchyCollaborators = async (projectUid) => {
  const { data } = await API.get(`${API_HOST}/api/v1/projects/${projectUid}/collaborators`);
  return data;
}

// public validation for email
export const ValidateUserEmail = async (email) => {
  const { data } = await API.post(`${API_HOST}/api/v1/user/exist`, { email });
  return data;
}

// public collaboration for email
export const InviteCollaborators = async ({ projectUid, email }) => {
  const { data } = await API.post(`${API_HOST}/api/v1/projects/${projectUid}/collaborators/invite`, { email });
  return data;
}

export const RemoveCollaborator = async ({ projectUid, userUid }) => {
  const { data } = await API.delete(`${API_HOST}/api/v1/projects/${projectUid}/collaborators/${userUid}`);
  return data;
}
