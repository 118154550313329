import User from '../../models/UserStore';
import { getUser } from "../../services/user";
import { parseProxyData, validateTrialFinish } from "../../utils/Utils";
import Storage from 'localStorage';
import { hookstate, useHookstate } from "@hookstate/core";
import userAvatar from '../../assests/img/user_avatar.png';

const initialState = hookstate({
  user: {
    has_subscription: '',
    requests_number: 0,
    days: ''
  },
  loadingUser: false
});

export default function useUserStore() {
  const { user, loadingUser } = useHookstate(initialState);

  const getUserInformation = () => {
    getUser(User.getUid())
      .then((response) => {
        Storage.setItem('avatar', response.data.img || userAvatar.toString())
        user.set(response.data)
      })
      .catch((error) => {
        console.error(error);
      })
  }

  return {
    user: parseProxyData(user.get()),
    loadingUser: loadingUser.get(),
    getUserInformation,
    showTrial: validateTrialFinish(user.get())
  }
}
