import { useState } from "react";
import { AddBranchyPdf } from "../../../services/seenapse";
import useNavigation from "../../../hooks/useNavigation";
import UserStore from "../../../models/UserStore";
import { useNotify } from "@fredybustos/usenotify";
import useBranchyStore from "../../../hooks/store/useBranchyStore";
import { updateBranch } from "../utils/updateBranch";

export default function useAddFile() {
  const [isloading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const { searchParams } = useNavigation();
  const { notifyError } = useNotify()
  const { getBranchy, onSetCurrentProject, currentProject } = useBranchyStore();

  const onChangeFile = (event, parentChatId) => {
    const file = event.target.files[0];

    const updatedProjects = updateBranch({
      data: currentProject,
      id: parentChatId,
      content: file.name
    })

    onSetCurrentProject(updatedProjects)
    onAddFile({ parentChatId, file });
  }

  const onAddFile = ({ parentChatId, file }) => {
    const userLang = navigator.language || navigator.userLanguage;
    const lang = userLang.split('-')[0];
    const form = new FormData();
    const userId = UserStore.getUid();
    form.append('file', file);
    form.append('user_uid', userId);
    form.append('lang', lang);
    form.append('parent_chat_id', parentChatId);

    setIsLoading(true);
    AddBranchyPdf({ form, projectUid: searchParams.projectUid })
      .then(() => {
        setIsSent(true);
        getBranchy(searchParams.projectUid)
      })
      .catch((error) => {
        console.error(error);
        notifyError('Error sending file');
      })
      .finally(() => setIsLoading(false));
  }

  return { onChangeFile, onAddFile, isloading, isSent }
}
