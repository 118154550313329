import React from 'react';
import Loader from '../../components/loader';
import SubscriptionDetail from './detail';
import './subscription.css';
import useUserStore from '../../hooks/store/userStore';

export default function Subscription ()  {
  const { user, showTrial, loadingUser } = useUserStore()
  const showRenew = user.days >= 7 && user.has_subscription === 2;

  return (
    <div className="main-content">
      {loadingUser
        ? (
          <Loader
            style={{
              marginTop: 80,
              zIndex: 500,
              backdropFilter: 'blur(12px)'
            }}
          />
        ) : (
          <SubscriptionDetail
            showRenew={showRenew}
            showTrialFinish={showTrial}
          />
        )
      }
    </div>
  );
};
