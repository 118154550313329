import { useEffect } from 'react';
import { Socket } from 'phoenix';
import { getToken } from '../../../utils/Utils';
import { WS_API_HOST } from '../../../shared/constants/Constants';
import useNavigation from '../../../hooks/useNavigation';

const token = getToken()
const socket = new Socket(WS_API_HOST, {});

export default function useChatSubscription(onSetCurrentProject) {
  const { searchParams } = useNavigation()

  useEffect(() => {
    if (token) {
      socket.connect({ uid: searchParams.projectUid, token: token });
      const channel = socket.channel(`chat:${searchParams.projectUid}`, {});

    channel.join()
      .receive('ignore', () => console.error('Auth error'))
      .receive('ok', () => console.log('Joined successfully'));

    channel.onError((e) => console.error('Oops', e));
    channel.onClose((e) => console.error('Closed', e));

    channel.on('user:entered', (data) => {
      console.log('User entered:', data);
    });

    channel.on('new:msg', (payload) => {
      onSetCurrentProject(payload.chat_history)
    });

    return () => {
      channel.leave();
        socket.disconnect();
      };
    }
  }, [searchParams.projectUid, token]);

  return { searchParams }
}
