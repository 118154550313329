import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Storage from 'localStorage'

import User from '../../models/UserStore'
import ApiUser from '../../shared/login/ApiUser'
import Translate from './../../utils/Translate'
import mixpanel from 'mixpanel-browser'

const Modal = ({ toggleModal, handleDeleteAccount, errorDelete }) => {
  return (
    <div className='lb on'>
      <div className='message check'>
        <div className='content'>
          <div className='title ml-0'>
            {errorDelete
              ? errorDelete
              : Translate.get(
                  'deleteAccountInfo',
                  'Warning: this action is irreversible. Deleting your account will delete all your personal data from our servers. Are you sure you want to continue?'
                )}
          </div>
          <div className='d-flex justify-content-around'>
            <button
              id='register'
              className='theme-yellow'
              onClick={handleDeleteAccount}
            >
              {Translate.get('deleteMyAccount', 'Delete my Account')}
            </button>
            <button id='cancel' onClick={toggleModal}>
              {Translate.get('cancel', 'Cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const DeleteAccount = () => {
  const userId = User.getUid()
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)
  const [error, setError] = useState('')

  function handleModal() {
    if (error) {
      setError('')
    }
    setOpenModal(!openModal)
  }

  async function handleDeleteAccount() {
    try {
      mixpanel.track('Delete account')
      await ApiUser.deleteAccount(userId)
      Storage.clear()
      window.location = '/'
    } catch (error) {
      Storage.clear()
      window.location = '/'
      setError(error.response.data.errors.detail)
    }
  }

  return (
    <div className='text-center'>
      <p>{Translate.get('dangerZone', 'Danger zone')}</p>
      <button id='register' className='theme-yellow' onClick={handleModal}>
        {Translate.get('deleteMyAccount', 'Delete my Account')}
      </button>
      {openModal && (
        <Modal
          toggleModal={handleModal}
          handleDeleteAccount={handleDeleteAccount}
          errorDelete={error}
        />
      )}
    </div>
  )
}
export default DeleteAccount
