import Translate from './Translate';
import { BAD_WORDS } from '../components/catalogs';
import Storage from 'localStorage';
import queryString from 'query-string'
import Intercom from '@intercom/messenger-js-sdk';

const imageRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
const validUrlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

const PREVIEW_TYPES = {
  gif: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  bmp: 'image',
  webp: 'image',
  pdf: 'pdf',
  mpeg: 'audio',
  mp3: 'audio',
  ogg: 'audio',
  wav: 'audio',
  default: 'default',
};

export const getMimeType = (url) => {
  const extension = url.match(imageRegex);
  const extensionType = extension ? extension[1] : 'default';
  return PREVIEW_TYPES[extensionType];
};

export const isValidUrl = url => validUrlRegex.test(url);

export const isImageWithText = (message) => {
  const urlMatch = message.match(/\[(https?:\/\/[^\]]+)\]/);
  const url = urlMatch ? urlMatch[1] : null;
  // Remove the URL from the message
  const cleanedMessage = message.replace(/\[https?:\/\/[^\]]+\]/, '');
  return {content: cleanedMessage || message, url: url}
};

export const scrollDown = ({ contentRef, scrollRef }) => {
  if (!scrollRef.current && !contentRef.current) return;
  const offsetTop = scrollRef.current ? scrollRef.current.offsetTop : 0;
  contentRef.current.scrollTo({
    top: offsetTop,
    behavior: 'smooth',
  });
};

export default class Utils {
  /**
   * @param {*} view Recibe el tipo de vista
   * activa y retorna un link
   */
  static getLinkEmailAuth(isLogin) {
    if (isLogin) {
      return '/login/email';
    }
    return '/register';
  }

  /**
   * Retorna el nivel del seenapse
   * @param {int} numberOfSeenapses
   */
  static getSeenapserLevel(numberOfSeenapses) {
    let rank;

    if (numberOfSeenapses === 0) {
      rank = Translate.get('newbie', 'Newbie');
    }

    if (numberOfSeenapses > 0 && numberOfSeenapses < 21) {
      rank = Translate.get('adept', 'Adept');
    }

    if (numberOfSeenapses > 20 && numberOfSeenapses < 100) {
      rank = Translate.get('skiled', 'Skilled');
    }

    if (numberOfSeenapses > 99 && numberOfSeenapses < 1000) {
      rank = Translate.get('expert', 'Expert');
    }

    if (numberOfSeenapses > 999) {
      rank = Translate.get('master', 'Master');
    }
    return rank;
  }
}

export const isInSummaryModal = (pathName) => {
  const lengthPathName = pathName.split('/');
  return lengthPathName.length > 5;
};

export const escapeSpecialCharacters = text => ({ __html: text });

export const defaultAvatar = (event, avatar) => {
  event.target.onerror = null;
  event.target.src = `${avatar}`;
};

export const UrlShortener = (location) => {
  const path = location.pathname.split('/');
  const pathCurrently = path.slice(0, path.length - 2);
  return pathCurrently.join('/');
};

export const isAllowedLength = (text) => {
  if (text) {
    const maxCharacters = 12;
    return !(text.__html.length > maxCharacters);
  }
};

export const getMaxHeight = (element) => {
  const valueTotalLineHeight = 2.6;
  const fontSize = window
    .getComputedStyle(element)
    .getPropertyValue('font-size')
    .replace('px', '');
  element.style.maxHeight = `${fontSize * valueTotalLineHeight}px`;
  return Math.round(fontSize * valueTotalLineHeight);
};

export const ellipsisText = (title) => {
  title.forEach((item) => {
    const maxHeight = getMaxHeight(item);
    while (item.offsetHeight > maxHeight) {
      item.innerText = item.innerText.replace(/\W*\s(\S)*$/, '...');
    }
  });
};

export const getHeigthText = () => {
  const p = document.createElement('p');
  p.style.fontSize = 'calc(7px + 4 * ((100vw - 320px) / (1600 - 320)))';
  document.body.appendChild(p);
  return getMaxHeight(p);
};

export const isImageUrl = (url) => {
  const imageExtensions = ['png', 'gif', 'svg', 'jpg', 'jpeg'];
  const dotSlots = url.split('.');
  const extension = dotSlots[dotSlots.length - 1];
  return imageExtensions.includes(extension);
};

export const signOut = (browserHistory) => {
  localStorage.clear();
  browserHistory.push('/');
};

export const decode = (token) => {
  const data = token.split('.')[0];

  const decoded = decodeURIComponent(
    Array.prototype.map
      .call(
        window.atob(data.replace(/_/g, '/').replace(/-/g, '+')),
        c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`,
      )
      .join(''),
  );

  return JSON.parse(decoded);
};

export const getTypeResult = (selected) => {
  switch (selected) {
    case 'followees':
      return 'people';
    case 'followers':
      return 'people';
    case 'favs':
      return 'seenapse'; // s
    case 'seenapses':
      return 'seenapses';
    case 'seenapsed':
      return 'seenapsed';
    case 'favorited':
      return 'favorited';
    case 'followed':
      return 'people';
    default:
      return 'seenapses';
  }
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const wordFilter = (value) => {
  const newValues = value.replace(/,/g, ' ').split(' ');

  const filteredValue = newValues.reduce((acc, current) => ({
    ...acc,
    [current.toLowerCase()]: current.toLowerCase(),
  }), {});

  return BAD_WORDS.map(item => filteredValue[item]).filter(item => item);
};

export const isTruthyValue = (value) => {
  return value !== null && value !== undefined
}

export const pickBy = (
  object,
  condition,
  recursive,
) => {
  const obj = Array.isArray(object) ? [] : {}

  for (const key in object) {
    if (condition(object[key], key)) {
      if (recursive && object[key] instanceof Object) {
        obj[key] = pickBy(object[key], condition, recursive)
      } else {
        obj[key] = object[key]
      }
    }
  }
  return obj
}

export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length

export const parseProxyData = (data) => JSON.parse(JSON.stringify(data));

export const getToken = () => Storage.getItem('token')

export const getUid = () => Storage.getItem('uid')

export const parseQuery = (params) => queryString.parse(params)

export const stringifyQuery = (
  params,
  arrayFormat = 'bracket'
) => {
  const _filters = pickBy(params, filter => {
    if (typeof filter === 'string') return filter.length > 0
    return true
  })
  // Add the query params
  return !isEmpty(_filters)
    ? `?${queryString.stringify(_filters, {
      arrayFormat: arrayFormat
    })}`
    : ''
}

export const isLoggedIn = () => {
  const hasNewVersion = Storage.getItem('newVersion')
  if (!hasNewVersion) {
    Storage.clear()
    Storage.setItem('newVersion', 'langversion')
  }

  const langStore = Storage.getItem('lang')
  const translateLang = Storage.getItem('translate_lang')
  if (!langStore || !translateLang) {
    const userLang = navigator.language || navigator.userLanguage
    const lang = userLang.split('-')[0] === 'es' ? 'es' : 'en'
    Storage.setItem('lang', lang)
    Storage.setItem('translate_lang', lang)
  }

  let uid = Storage.getItem('uid')
  return uid !== null && uid != "null" ? true : false
}

// If days are less than 7 to show the form
// If days are more than 7 and suscription == null to show the trail was finish
// If days are more than 7 and suscription == 1 to show the form
// If days are more than 7 and suscription == 2 to show the renew message
export const validateTrialFinish = (user) => {
  return (user.days >= 7 || user.requests_number >= 30) && (user.has_subscription === 0 || user.has_subscription === 2);
}

export const intercomConnection = ({ name, email, userId }) => {
  const currentDate = new Date();
  const signupDateUnixTimestamp = Math.floor(currentDate.getTime() / 1000);
  return Intercom({
    app_id: 'kyddub70',
    user_id: userId,
    name: name,
    email: email,
    created_at: signupDateUnixTimestamp,
  });
}
